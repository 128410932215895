
.after-sales{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding:  0 20px;
    .add-address{
        margin-top: 20px;
    }
}

